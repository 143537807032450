import { decorate, action, observable, reaction, runInAction, computed } from "mobx";
import api from '../api';
import ChatConnection from "../components/Chat/ChatConnection";
import config from '../config';
import uiConfig from '../uiConfig';

const MAX_REFRESH_COUNT = 3;

function checkVersion(portalVersion) {
  const lsRefreshCountPath = `${config.portalName}-refresh-count-${config.version}-${portalVersion}`;
  if (portalVersion > config.version) {
    let refreshCount = parseInt(localStorage.getItem(lsRefreshCountPath));
    if (isNaN(refreshCount))
      refreshCount = 0;
    localStorage.setItem(lsRefreshCountPath, ++refreshCount);
    if (refreshCount < MAX_REFRESH_COUNT)
      location.reload();
  }
}
class AppStore {
  constructor() {
    this.currentUser = null;
    this.isLoggingIn = false;
    this.isLoggedIn = false;
    this.search = "";
    this.isFetching = false;
    this.events = [];
    this.products = [];
    this.portal = null;
    this.chatConnection = null;
    this.chatMessages = [];
    reaction(() => this.currentUser, () => this.initChat());
    reaction(() => this.events.length, () => this.initChat());
  }

  async initChat() {
    if (!uiConfig.showChat)
      return;
    if (!this.currentUser || this.events.length === 0)
      return;
    if (!this.events.some(x => x.enableExhibitHall && x.hasExhibitHallAccess))
      return;
    console.log("initChat called", this.currentUser, this.events);

    let password;
    try {
      password = await api.getChatPassword(this.currentUser.email);
    } catch (error) {
      runInAction(() => this.chatConnection = { state: "Error" });
      return console.error("[STORE]", "get chat password error", this.currentUser.email, error.message || error);
    }

    const connection = new ChatConnection({ username: this.currentUser.email, password, });

    const onNewMessage = (events) => {
      console.log("[CHAT]", "new message events", events);
      for (let event of events) {
        // addMessage(event.message);
        runInAction(() => this.chatMessages = [...this.chatMessages, event.message]);
      }
    }
    const onDeleteMessage = (events) => {
      console.log("[CHAT]", "new delete events", events);
      // setMessages(x => x.filter(x => !events.map(c => c.message_id).includes(x.id)));
      runInAction(() => this.chatMessages = this.chatMessages.filter(x => !events.map(c => c.message_id).includes(x.id)));
    }
    const onUpdateMessageFlags = (events) => {
      console.log("[CHAT]", "new update flags events", events);
      runInAction(() => {
        let newMessages = [...this.chatMessages];
        for (let event of events) {
          // console.log("[CHAT]", "new update flags event", event);
          for (let messageId of event.messages) {
            const index = newMessages.findIndex(x => x.id === messageId);
            if (event.operation === "add") {
              newMessages[index].flags = [...(newMessages.flags || []), event.flag];
              // console.log("message updated", newMessages.flags, newMessages[index], newMessages);
            }
          }
        }
        this.chatMessages = newMessages;
      });
    }
    const messages = await connection.init(null, onNewMessage, onDeleteMessage, onUpdateMessageFlags);
    console.log("[CHAT]", "got messages", messages);
    runInAction(() => this.chatConnection = connection);
    if (!messages)
      return console.error("CHAT", "error", "init failed");
    // setMessages(messages);
    runInAction(() => this.chatMessages = messages.filter(x => !x.sender_realm_str));
  }

  get unreadMessagesCount() {
    if (!this.chatConnection?.profile)
      return 0;
    // console.log("computed", [...this.chatMessages], this.chatMessages.filter(c => c.sender_id !== this.chatConnection.profile.user_id && !(c.flags || []).includes("read")));
    return this.chatMessages.filter(c => c.sender_id !== this.chatConnection.profile.user_id && !(c.flags || []).includes("read")).length;
  }

  setEvents(events) {
    this.events = events;
  }

  setPortal(portal) {
    this.portal = portal;
  }

  setProducts(products) {
    this.products = products;
  }

  setUser(userData) {
    this.currentUser = userData;
    this.isLoggingIn = false;
    this.isLoggedIn = !!userData;
  }

  setIsLoggingIn(val) {
    this.isLoggingIn = !!val;
  }

  setSearch(val) {
    this.search = val;
  }
  setIsFetching(val) {
    this.isFetching = val;
  }
  fetchPortal() {
    return new Promise((resolve, reject) => {
      api.getPortal().then(portal => {
        checkVersion(portal.version);
        this.setEvents(portal.events);
        this.setProducts(portal.products);
        this.setPortal(portal);
        resolve(portal);
      }).catch(reject);
    });
  }
  fetchEvents() {
    return new Promise((resolve, reject) => {
      api.getEvents().then(resp => resp.body).then(events => {
        this.setEvents(events);
        resolve(events);
      }).catch(reject);
    });
  }
}

decorate(AppStore, {
  currentUser: observable,
  isLoggingIn: observable,
  isLoggedIn: observable,
  isFetching: observable,
  search: observable,
  portal: observable,
  events: observable,
  products: observable,
  chatConnection: observable,
  chatMessages: observable,
  unreadMessagesCount: computed,
  setUser: action,
  setEvents: action,
  setPortal: action,
  fetchEvents: action,
  initChat: action,
  //setSearch: action,
});

const configureStore = () => new AppStore();

export default configureStore;
