import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/Delete';
import Grid from "@material-ui/core/Grid";
import api from "../../api";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useFeedback } from "../feedback/Service";
import { inject } from "mobx-react";
import usePortal from "../hooks/portal";
import moment from 'moment';
// import { useHistory } from "react-router-dom";
import DataTable from "../DataTable";
import Typography from "@material-ui/core/Typography";
import config from '../../config';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import UserEventsDialog from "../dialogs/UserEventsDialog";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import uiConfig from '../../uiConfig';

const getLoginTime = user => {
  if (!user.lastLoginTime) return "no data";
  return moment(user.lastLoginTime).format("LLL") + " UTC";
}

const loginCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "date", title: "Date" },
];

const salesCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "description", title: "Description" },
  { name: "amount", title: "Amount" },
  { name: "amountRefunded", title: "Amount Refunded" },
  { name: "date", title: "Date" },
];

const certificatesCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "sessionName", title: "Session" },
  { name: "date", title: "Date" },
  { name: "score", title: "Score" },
];

const Users = ({ store, isFetching, user, events, portal }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEvents, setUserEvents] = useState([]);
  const [eventsDialogUserId, setEventsDialogUserId] = useState(null);
  const [sendEventEmail, setSendEventEmail] = useState(false);
  const [logins, setLogins] = useState([]);
  const [sales, setSales] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [salesTab, setSalesTab] = useState(0);
  const [isFetchingSales, setIsFetchingSales] = useState(false);
  const [isFetchingConnectionTrackerData, setIsFetchingConnectionTrackerData] = useState(false);
  const [isFetchingPlaybackTrackerData, setIsFetchingPlaybackTrackerData] = useState(false);
  const [isFetchingSessionEvaluationData, setIsFetchingSessionEvaluationData] = useState(false);
  const [isFetchingSignUpData, setIsFetchingSignUpData] = useState(false);

  // const history = useHistory();
  const { portalId, portalName, /* urlPrefix */ } = usePortal();

  const feedback = useFeedback();

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length > 2)
      fetch(value);
  }

  const handleChangePassword = email => async () => {
    const password = await feedback.form({ title: "Change Password", input: { title: 'New password', type: "password", name: "password" } });
    if (!password)
      return;
    store.setIsFetching(true);
    api.changePassword({ email, password }).then(() => {
      feedback.snackbar({ text: "Password changed.", type: "success" });
      store.setIsFetching(false);
    }).catch(x => {
      console.log(x);
      store.setIsFetching(false);
      feedback.snackbar({ text: x.responseJSON?.message || "Error changing password", type: "error" });
    });
  }

  const handleToggleAdmin = user => async () => {
    store.setIsFetching(true);
    api.updateUser({ ...user, hasAccessToUserManagement: !user.hasAccessToUserManagement }).then(() => {
      feedback.snackbar({ text: "User updated", type: "success" });
      store.setIsFetching(false);
      fetch(search);
    }).catch(x => {
      console.log(x);
      store.setIsFetching(false);
      feedback.snackbar({ text: x.responseJSON?.message || "Error updating user", type: "error" });
    });
  }

  const handleOpenEvents = id => () => setEventsDialogUserId(id);

  const handleSubmit = async e => {
    e.preventDefault();
    store.setIsFetching(true);
    try {
      const { body: user } = await api.addUser({ email, firstName, lastName, password, hasAccessToUserManagement: isAdmin, sendEventEmail, events: userEvents }, portalName);
      console.log(user);
      // for (let eventId of userEvents) {
      //   await api.addUserToEvent(eventId, user.id, { sendEventEmail, pwd: password });
      // }
      setEmail('');
      setFirstName('');
      setLastName('');
      setPassword('');
      setDefaultEvent();
      store.setIsFetching(false);
      feedback.snackbar({ text: "User has been successfully added.", type: "success" });
    } catch (error) {
      console.log(error);
      store.setIsFetching(false);
      feedback.snackbar({ text: error.responseJSON?.message || "Error adding user", type: "error" });
    }
  }

  const fetch = searchString => {
    store.setIsFetching(true);
    api.getUsers(searchString, portalId, portalName).then(({ body: data }) => {
      console.log(data);
      setUsers(data);
      store.setIsFetching(false);
    });
  }

  const handleDelete = ({ id, email }) => async e => {
    if (!await feedback.confirm({ title: "Remove user?", text: `Are you sure you want to delete ${email} from users?` }))
      return;
    api.deleteUser(id, portalName).then(x => {
      fetch(search);
      feedback.snackbar({ text: 'User has been successfully deleted.', type: "success" });
    }).catch(x => {
      feedback.snackbar({ text: x.responseJSON?.message || "Error deleting user", type: "error" });
    });
  }

  const handleGetLoginsClick = () => {
    api.getLoginsCsv().then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "logins.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  const handleGetSignUpsClick = id => () => {
    setIsFetchingSignUpData(true);
    api.getSignUpsCsv(id).then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "signups.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
      setIsFetchingSignUpData(false);
    }).catch(error => feedback.snackbar({ text: error.message, type: "error" }));
  }

  const handleGetConnectionTrackerDataClick = eventId => () => {
    setIsFetchingConnectionTrackerData(true);
    api.getEventConnectionTrackerDataCsv(eventId).then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "connection_tracker_data.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
      setIsFetchingConnectionTrackerData(false);
    }).catch(error => feedback.snackbar({ text: error.message, type: "error" }));
  }

  const handleGetAttentionTrackerDataClick = eventId => () => {
    setIsFetchingConnectionTrackerData(true);
    api.getEventAttentionTrackerDataCsv(eventId).then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "attention_tracker_data.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
      setIsFetchingConnectionTrackerData(false);
    }).catch(error => feedback.snackbar({ text: error.message, type: "error" }));
  }

  const handleGetPlaybackTrackerDataClick = () => {
    setIsFetchingPlaybackTrackerData(true);
    api.getPlaybackTrackerDataCsv().then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "playback_tracker_data.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
      setIsFetchingPlaybackTrackerData(false);
    }).catch(error => feedback.snackbar({ text: error.message, type: "error" }));
  }

  const handleGetSessionEvaluationDataClick = id => () => {
    setIsFetchingSessionEvaluationData(true);
    api.getSessionEvaluationDataCsv(id).then((res) => {
      const attachement = res.headers["content-disposition"];
      const url = URL.createObjectURL(res.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "session_evaluation_data." + attachement.split(".").pop();
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
      setIsFetchingSessionEvaluationData(false);
    }).catch(error => feedback.snackbar({ text: error.message, type: "error" }));
  }

  const handleGetSalesClick = () => {
    api.getSalesCsv().then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "sales.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  const handleGetCertificatesClick = () => {
    api.getLastCertificatesCsv().then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "certificates.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  const setDefaultEvent = useCallback(() => {
    if (userEvents.length > 0)
      return;
    if (events?.length < 1) {
      setUserEvents([]);
      return;
    }
    const upcomingEvents = events.filter(x => moment.duration(moment() - moment(x.endDate || x.startDate)).asDays() < 2);
    if (upcomingEvents.length > 0)
      setUserEvents([upcomingEvents[0].id]);
    else
      setUserEvents([]);
  }, [events]);

  // useEffect(() => {
  //   if (!user.role)
  //     history.push(urlPrefix);
  // }, [user]);

  useEffect(() => {
    const getSales = async days => {
      setSales([]);
      setIsFetchingSales(true);
      const sales = await api.getSales(days);
      setSales(sales.map(x => ({ ...x, date: moment(x.created).format("LLL") })));
      setIsFetchingSales(false);
    }
    const days = salesTab === 0 ? 1 : (salesTab === 1 ? 7 : 30);
    getSales(days);
  }, [salesTab]);

  useEffect(() => {
    const getData = async () => {
      const logins = await api.getLogins();
      setLogins(logins.map(x => ({ ...x, date: moment(x.dateTime).format("LLL") })));
      setIsFetchingSales(true);
      const sales = await api.getSales(1);
      console.log("sales", sales);
      setSales(sales.map(x => ({ ...x, date: moment(x.created).format("LLL") })));
      setIsFetchingSales(false);
      const certificates = await api.getLastCertificates();
      console.log("certificates", certificates);
      setCertificates(certificates.map(x => ({ ...x, date: moment(x.issued).format("LLL") })));
    }
    getData();
  }, []);

  useEffect(() => {
    setDefaultEvent();
  }, [setDefaultEvent]);

  console.log("events", events?.length);

  return (
    <>
      <UserEventsDialog open={!!eventsDialogUserId} userId={eventsDialogUserId} handleClose={() => setEventsDialogUserId(null)} />
      <br />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder="First Name"
              // margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              placeholder="Last Name"
              // margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
              // margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              required
              autoComplete="new-email"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Password"
              // margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              // required
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="events-label">Events</InputLabel>
              <Select
                style={{ minWidth: 150 }}
                labelId="events-label"
                id="event-select"
                value={userEvents}
                onChange={e => setUserEvents(e.target.value)}
                multiple
                fullWidth
              >
                {/* <MenuItem value={0}>Select event</MenuItem> */}
                {events.map(event => (<MenuItem value={event.id} key={event.id}>{event.name}</MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
          {userEvents.length > 0 &&
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEventEmail}
                    onChange={e => setSendEventEmail(e.target.checked)}
                    color="primary"
                  />
                }
                label={"Send Event Email"}
              />
            </Grid>
          }
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAdmin}
                  onChange={e => setIsAdmin(e.target.checked)}
                  color="primary"
                />
              }
              label={"Admin"}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" disabled={isFetching}>Create User</Button>
          </Grid>
        </Grid>
      </form>
      <br />
      <br />
      <TextField
        fullWidth
        type="search"
        value={search}
        onChange={handleChange}
        name="search"
        placeholder="Search users by email..."
        margin="dense"
        size="small"
        variant="outlined"
      />
      <List component="nav" aria-labelledby="certificates-list-subheader"      >
        {users.map((user, i) => (
          <ListItem key={i}>
            <ListItemText primary={user.email} secondary={user.firstName + " " + user.lastName + ", Last login: " + getLoginTime(user) + (user.hasAccessToUserManagement ? ", admin" : "")} />
            <Button color="primary" onClick={handleChangePassword(user.email)}>Change Password</Button>
            <Button color="primary" onClick={handleOpenEvents(user.id)}>Events</Button>
            <Button color="primary" onClick={handleToggleAdmin(user)}>{user.hasAccessToUserManagement ? "Revoke Admin" : "Grant Admin"}</Button>
            <IconButton onClick={handleDelete(user)}>
              <RemoveIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <br />
      {(events.some(x => x.signUpFormMetaData) || portal.signUpFormMetaData) &&
        <>
          <Typography variant="h6" gutterBottom>Sign Up Data</Typography>
          {isFetchingSignUpData ?
            <LinearProgress variant="query" /> :
            <List component="nav" aria-labelledby="events-list-subheader">
              {portal.signUpFormMetaData &&
                <ListItem button onClick={handleGetSignUpsClick(portal.id)}>
                  <ListItemText primary={"Portal - Download CSV"} />
                </ListItem>
              }
              {events.filter(x => x.signUpFormMetaData).map(event => (
                <ListItem key={event.id} button onClick={handleGetSignUpsClick(event.id)}>
                  <ListItemText primary={event.name + " - Download CSV"} />
                </ListItem>
              ))}
            </List>
          }
          <br />
        </>
      }
      {(portal.events.some(x => ["connection", "attention"].includes(x.tracking)) || user.isSuperAdmin) &&
        <>
          <Typography variant="h6" gutterBottom>Events Trackers Reports Download</Typography>
          {isFetchingConnectionTrackerData ?
            <LinearProgress variant="query" /> :
            <List component="nav" aria-labelledby="events-list-subheader">
              {events.map(event => (
                <ListItem key={event.id}>
                  <ListItemText primary={event.name} />
                  {event.tracking === "attention" ?
                    <Button variant="outlined" onClick={handleGetAttentionTrackerDataClick(event.id)} style={{ marginLeft: 8 }}>Attention Tracker</Button> :
                    <Button variant="outlined" onClick={handleGetConnectionTrackerDataClick(event.id)} style={{ marginLeft: 8 }}>Connection Tracker</Button>
                  }
                  {/* <Button variant="outlined" onClick={handleGetPlaybackTrackerDataClick(event.id)} style={{ marginLeft: 8 }}>Playback Tracker</Button> */}
                </ListItem>
              ))}
            </List>
          }
        </>
      }
      {(uiConfig.enablePlaybackTracking || user.isSuperAdmin) &&
        <>
          <br />
          <Typography variant="h6" gutterBottom>Playback Tracker Report Dowload</Typography>
          {isFetchingPlaybackTrackerData ?
            <LinearProgress variant="query" /> :
            <Button onClick={handleGetPlaybackTrackerDataClick} variant="contained" >Download CSV</Button>
          }
        </>
      }
      <br />
      {(events.some(x => x.evaluationFormMetaData) || portal.evaluationFormMetaData) &&
        <>
          <Typography variant="h6" gutterBottom>Session Evaluation Data</Typography>
          {isFetchingSessionEvaluationData ?
            <LinearProgress variant="query" /> :
            <List component="nav" aria-labelledby="events-list-subheader">
              {portal.evaluationFormMetaData &&
                <ListItem button onClick={handleGetSessionEvaluationDataClick(portal.id)}>
                  <ListItemText primary={"Portal - Download CSV"} />
                </ListItem>
              }
              {events.filter(x => x.evaluationFormMetaData).map(event => (
                <ListItem key={event.id} button onClick={handleGetSessionEvaluationDataClick(event.id)}>
                  <ListItemText primary={event.name + " - Download CSV"} />
                </ListItem>
              ))}
            </List>
          }
        </>
      }
      <br />
      <Typography variant="h6" gutterBottom>Last 10 logins</Typography>
      <Button onClick={handleGetLoginsClick} variant="contained" >Download CSV</Button>
      <br />
      <br />
      <DataTable cols={loginCols} rows={logins} />
      <br />
      <br />
      <Typography variant="h6" gutterBottom>Last sales</Typography>
      <Button onClick={handleGetSalesClick} variant="contained" >Download CSV</Button>
      <br />
      <br />
      <Tabs
        value={salesTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, v) => setSalesTab(v)}
      >
        <Tab label="Today" />
        <Tab label="Last 7 days" />
        <Tab label="Last 30 days" />
      </Tabs>
      <DataTable cols={salesCols} rows={sales} />
      {isFetchingSales && <LinearProgress variant="query" />}
      {config.hasCme &&
        <>
          <br />
          <br />
          <Typography variant="h6" gutterBottom>Last 10 certificates</Typography>
          <Button onClick={handleGetCertificatesClick} variant="contained" >Download CSV</Button>
          <br />
          <br />
          <DataTable cols={certificatesCols} rows={certificates} />
        </>
      }
    </>
  );
};

Users.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired
}

export default inject(({ store }, props) => ({
  isFetching: store.isFetching, store, user: store.currentUser, events: store.events, portal: store.portal,
}))(Users);
