import React from 'react';

// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Chat from '../Chat/Chat';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dialog: {
    // padding: '0 0 10px 10px !important',
    padding: "0 !important",
    minHeight: 200,
  },
  chatContainer: {
    // height: '100%',
    display: 'flex',
    flexDirection: "column",
    // height: 'calc(100vh - 100px)',
    height: 500,
  },
  chatWrapper: {
    flex: 1,
  }
}));

const ChatDialog = ({ show, handleClose, ...rest }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="chat-dialog-title"
      aria-describedby="chat-dialog-description"
      fullWidth
      scroll='paper'
    // maxWidth="md"
    >
      {/* <DialogTitle id="chat-dialog-title">Chat with Exhibitor</DialogTitle> */}
      <DialogContent className={classes.dialog}>
        <div className={classes.chatContainer}>
          <div className={classes.chatWrapper}>
            <Chat {...rest} show />
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}
    </Dialog>
  );
}

export default ChatDialog;
