const config = {
  CMEfilter: false,
  mainColor: "#b5179e",
  primaryContrastText: "#fff",
  secondaryColor: "#3a0ca3",
  secondaryContrastText: "#fff",
  searchPlaceholder: "What do you want to learn?",
  loginText: "",
  requireAuth: true,
  showOnDemand: false,
  showBuy: false,
  showSignUp: false,
  showSponsors: true,
  showSponsorsPage: false,
  sponsorsPageCards: false,
  sponsorsCardsHorizontal: false,
  showEvents: false,
  eventsDebugMode: false,
  showProducts: false,
  showSchedule: false,
  trackPageScrollOffset: -80,
  showExhibitHall: false,
  showChat: false,
  enablePlaybackTracking: false,
  showPrivacyPolicy: false,
  // externalPrivacyPolicyUrl: "//www.ya.ru",
  // acceptPrivacyPolicyText: "Accept <Link>",
  emailHelperText: "",
  showSessionEvaluation: false,
  showRecordingEvaluation: false,
  showAskSpeaker: false,
  supportEmail: "info@slidespiel.com",
  portals: [
    { 
      year: 2020,
      logo: 'logo.png',
      signInRedirect: "/event/aafa-2022-05-25",
      trackImages: false,
      home: {
        minHeight: 500,
        height: '70vh',
        paperMarginType: 'Right',
        paperMargin: '0vw',
        paperMaxWidth: 800,
        paperColor: 'rgba(0,0,0,.7)',
        backgroundUrl: 'background.png',
        backgroundPosition: { x: 50, y: 40 },
        backgroundRatio: 0.25,
        justifyContent: 'center',
        alignItems: 'center',
        color: "#fff",
        buttonColor: "primary",
        getLink: "/event/aafa-2022-05-25/login",
        showHero: true,
        showCta: true,
        showCtaText: true,
        ctaPadding: [2, 4],
        //fontFamily: "Fira Sans",
        //fontWeight: 'bold',
      },
    },
  ],
  main: {
    logoMarginTop: 5  ,
    logoPadding: 0,
    logoHeight: 70,
    logoHeightXs: 50,
    progressBarTop: 79,
    progressBarTopXs: 55,
    childrenMarginTop: 79,
    childrenMarginTopXs: 50,
    searchColor: "primary",
    barColor: "default",
    barTextColor: "primary",
    barButtonColor: "primary"
  },
  footer: {
    // facebookUrl: "https://www.facebook.com/",
    // twitterUrl: "https://twitter.com/",
    // instagramUrl: "https://instagram.com/",
    // linkedInUrl: "https://www.linkedin.com/",
  },
  eventHome: {
    titleColor: "primary",
    subtitleColor: "secondary",
    liveColor: "secondary",
    registerColor: "primary",
    exhibitHallColor: "secondary",
    networkingRoomColor: "primary",
  }
};

export default config;
