import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
// import Link from '@material-ui/core/Link';
import { Document, Page, pdfjs } from 'react-pdf';
import { makeStyles } from '@material-ui/core/styles';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
  root: {
    "& canvas": {
      width: "100% !important",
      height: "auto !important",
    },
    "& .react-pdf__Page__textContent, .react-pdf__Page__annotations": {
      display: "none"
    }
  },
  pager: {
    display: 'flex',
    justifyContent: 'center',
    margin: 8
  }
}));

const PdfViewer = ({ fileId, api, exhibitorId }) => {
  const [pagesCount, setPagesCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [url, setUrl] = useState(null);
  const [loaded, setLoaded] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();

  const onDocumentLoadSuccess = ({ numPages }) => setPagesCount(numPages);

  const handlePageChange = (_, page) => setPageNumber(page);

  const renderPagination = () => pagesCount > 1 ? <Pagination className={classes.pager} count={pagesCount} page={pageNumber} color="primary" onChange={handlePageChange} /> : null;

  const handleClick = e => {
    if (showDialog)
      return;
    e.preventDefault();
    setShowDialog(true);
  }

  useEffect(() => {
    setPagesCount(null);
    setUrl(null);
    setPageNumber(1);
    const onProgress = ({ percent }) => setLoaded(percent);
    api.getExhibitorFile(exhibitorId, fileId, onProgress).then((blob) => {
      const url = URL.createObjectURL(blob.body);
      setUrl(url);
      setLoaded(0);
    });
  }, [fileId, api, exhibitorId]);

  useEffect(() => {
    const handleHotKeys = e => {
      if (e.target.tagName === "INPUT") return;
      switch (e.which) {
        case 33:
        case 37:
        case 38:
        case 80:
          return setPageNumber(x => x > 1 ? x - 1 : x);
        case 34:
        case 39:
        case 40:
        case 78:
        case 32:
          return setPageNumber(x => x < pagesCount ? x + 1 : x);
        // no default
      }
    }
    document.addEventListener("keyup", handleHotKeys, false);
    return () => {
      document.removeEventListener("keyup", handleHotKeys, false);
    }
  }, [pagesCount]);

  return (
    <div className={classes.root}>
      {!pagesCount &&
        <>
          <Typography variant="body2">Loading document...</Typography>
          <LinearProgress variant="determinate" value={loaded} style={{ width: '100%' }} />
        </>}
      {url &&
        <>
          {renderPagination()}
          <a href="#view" onClick={handleClick}>
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Dialog
                open={showDialog}
                fullWidth
                maxWidth={"lg"}
                onClose={() => setShowDialog(false)}
                scroll={"body"}
                // style={{ zIndex: 2000 }}
                className={classes.root}
              >
                <Page pageNumber={pageNumber} />
              </Dialog>
              <Page pageNumber={pageNumber} />
            </Document>
          </a>
          {renderPagination()}
        </>
      }
    </div>
  );
}

export default PdfViewer;