import { inject } from 'mobx-react';
import React, { useState, useMemo } from 'react';
import Chat from '../Chat/Chat';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 200
  },
  chatContainer: {
    // height: '100%',
    display: 'flex',
    flexDirection: "column",
    height: 'calc(100vh - 100px)',
    // height: 500
  },
  chatWrapper: {
    flex: 1,
  }
}));

const ChatPage = ({ messages, connection }) => {
  const [selected, setSelected] = useState(null);
  const handleItemClick = item => () => setSelected(item);
  const classes = useStyles();
  const history = useHistory();
  // console.log("selected", selected);

  const senders = useMemo(() => {
    if (!connection || !messages)
      return [];
    // console.log("connection.profile.user_id", connection.profile.user_id);
    const senderIds = messages.map(x => x.sender_id).filter(x => x !== connection.profile.user_id);
    return [...new Set(senderIds)].map(senderId => ({
      id: senderId,
      name: messages.find(c => c.sender_id === senderId).sender_full_name,
      unreadCount: messages.filter(c => c.sender_id === senderId && !(c.flags || []).includes("read")).length,
    }));
  }, [messages, connection]);

  const handleClose = () => history.goBack();

  if (connection?.state !== "Connected")
    return <Typography>Loading...</Typography>

  return (
    <Dialog open onClose={handleClose} aria-labelledby="chats-dialog-title" fullWidth maxWidth="md" scroll='body'>
      {/* <DialogTitle id="chats-dialog-title">Private Chat</DialogTitle> */}
      {/* <DialogContent> */}
      <Grid container className={classes.root}>
        <Grid item xs={12} md={3}>
          <List
            dense
            component="nav"
            aria-labelledby={"chat-list-subheader"}
            subheader={
              <ListSubheader component="div" id={"chat-list-subheader"} className={classes.subheader}>
                Chats
              </ListSubheader>
            }
          >
            {senders.map(item => (
              <ListItem button key={item.id} selected={selected === item.id} onClick={handleItemClick(item.id)}>
                <ListItemText primary={item.name} />
                <Badge badgeContent={item.unreadCount} color="primary" />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={9}>
          {selected &&
            <div className={classes.chatContainer}>
              <div className={classes.chatWrapper}>
                <Chat show credentials={{ recipientId: selected }} />
              </div>
            </div>
          }
        </Grid>
      </Grid>
      {/* </DialogContent> */}
    </Dialog>
  );
};

export default inject(({ store }, props) => ({
  connection: store.chatConnection, messages: store.chatMessages, ...props
}))(ChatPage);