import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { makeStyles } from '@material-ui/core/styles';
import { useFeedback } from "../feedback/Service";

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    float: 'right'
  },
  formControl: {

  },
  explanation: { 
    whiteSpace: "pre-wrap"
  }
}));

const QuizQuestions = props => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const feedback = useFeedback();

  const handleSubmitAnswer = (e) => {
    e.preventDefault();
    props.onSubmit(selectedAnswer);
  }

  const handleAnswerSelect = (e) => setSelectedAnswer(e.target.value);

  const handleAbort = async () => {
    const confirm = await feedback.confirm({ title: "Are you sure you want to abort?", text: "The test will restart, but you will lose an attempt." });
    if (!confirm) return;
    props.onRetryClick();
  }

  const classes = useStyles();
  const { text, answers } = props.question;
  return (
    <>
      <br />
      <form onSubmit={handleSubmitAnswer}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">{text}</FormLabel>
          <br />
          <RadioGroup aria-label="gender" name="gender1" value={selectedAnswer} onChange={handleAnswerSelect}>
            {answers.map((answer) => {
              const answerExplanation = selectedAnswer === answer.id && props.answerExplanation,
                showAnswers = answerExplanation || !props.answerExplanation;
              return (
                <Fragment key={answer.id}>
                  {showAnswers && <FormControlLabel value={answer.id} control={<Radio color="primary" />} label={answer.text} />}
                  {answerExplanation && <Typography className={classes.explanation}>{props.answerExplanation}</Typography>}
                </Fragment>
              )
            })}
          </RadioGroup>
        </FormControl>
        <br />
        <br />
        <div>
          <Button variant="contained" color="secondary" onClick={handleAbort} disabled={props.isSubmitLocked}>Abort</Button>
          <Button variant="contained" color="primary" className={classes.button} type="submit" disabled={props.isSubmitLocked || !selectedAnswer}>Next</Button>
        </div>
      </form>
    </>
  );
};

QuizQuestions.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitLocked: PropTypes.bool
};

export default QuizQuestions;
