import React from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import config from '../../config';
import { makeStyles } from '@material-ui/core/styles';

import draftToHtml from 'draftjs-to-html';

const parseSummary = text => {
  try {
    return draftToHtml(JSON.parse(text));
  }
  catch {
    return text;
  }
}

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    float: 'right'
  },
}));

const QuizCourseReview = ({recording, onNextClick, isNextLocked}) => {
  const getSpeakersString = (speakers) => {
    return speakers.map((speaker) => `${speaker.firstName} ${speaker.lastName}`).join(", ");
  };
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" gutterBottom>Speakers</Typography>
      <Typography>{getSpeakersString(recording.speakers)}</Typography>
      <br />
      <Typography variant="h6" gutterBottom>Summary</Typography>
      <Typography dangerouslySetInnerHTML={{ __html: parseSummary(recording.summary) }} />
      <br />
      {recording.goals &&
        <>
          <Typography variant="h6" gutterBottom>Learning goals and objectives</Typography>
          <Typography>{recording.goals}</Typography>
          <br />
        </>
      }

      <Typography variant="h6" gutterBottom>Credit Information</Typography>
      <Typography>{recording.ceHours} Hours CME Credit(s)</Typography>

      <Button variant="contained" color="primary" className={classes.button} onClick={onNextClick} disabled={isNextLocked}>{config.questionsBeforeRecording ? "Start Pre-test" : "Next"}</Button>
    </>
  );
};

QuizCourseReview.propTypes = {
  recording: PropTypes.object.isRequired,
  onNextClick: PropTypes.func.isRequired,
  isNextLocked: PropTypes.bool
};

export default QuizCourseReview;
