import React, { Component, Fragment } from "react";
//import PropTypes from 'prop-types';
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import RecordingDialog from "../dialogs/RecordingDialog";
// import BuyRecordDialog from "../dialogs/BuyRecordDialog";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as Scroll from 'react-scroll';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import api from "../../api";
import uiConfig from "../../uiConfig";
import Filter from '../Filter';
import TracksGridList from '../TracksGridList';
import TracksList from '../TracksList';
import RecordingDetailsDialog from '../RecordingDetailsDialog';
import RecordingCard from '../RecordingCard';
import Hidden from '@material-ui/core/Hidden';

import usePortal from "../hooks/portal";
import config from '../../config';
import SignUpAndBuyDialog from "../dialogs/SignUpAndBuyDialog";
import { LogOnMount } from 'react-amplitude-hooks';

const getUpdatedTracks = (tracks, recording) => {
  let newTracks = [...tracks];
  let tracksWithRecording = newTracks.filter(x => x.recordings?.some(c => c.id === recording.id));
  for (let track of tracksWithRecording) {
    let index = track.recordings.findIndex(x => x.id === recording.id);
    let newRecordings = [...track.recordings];
    newRecordings[index] = recording;
    track.recordings = newRecordings;
  }
  return newTracks;
}

class TrackPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecordOpened: false,
      isRecordBuyOpened: false,
      isSearching: false,
      cmeTotal: 0,
      nonCmeTotal: 0,
      tracks: [],
      filteredTracks: [],
      filter: 0,
      typeFilter: 0,
      width: 0,
      data: {},
      recordingDetails: {},
    }
  }

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    this.props.store.setIsFetching(false);
  }

  doSearch = () => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => this.handleSearch(), 700);
  }

  componentDidUpdate(prevProps) {
    const { search, user } = this.props;
    if (search !== prevProps.search) {
      this.setState({ isSearching: search.length > 1 }, this.doSearch);
    }
    if (user && prevProps.user !== user) {
      this.setState({
        tracks: [],
        filteredTracks: [],
        tracksSearchMatch: new Array(0).fill([]),
      }, () => this.getData());
    }
  }

  handleDetailsModalOpen = id => () => this.setState(prevState => ({ recordingDetails: prevState.tracks.map(x => x.recordings).flat().find(x => x?.id === id) || {} }));
  handleDetailsModalClose = () => this.setState({ recordingDetails: {} });
  handleBuyClose = (data) => {
    this.setState({ isRecordBuyOpened: false, data: {} });
    if (!data) return;
    if (data === 'all') {
      this.getData();
      return;
    }
    const updateRecording = recording => {
      let newTracks = getUpdatedTracks(this.state.tracks, recording);
      let newFilteredTracks = getUpdatedTracks(this.state.filteredTracks, recording);
      this.setState({ tracks: newTracks, filteredTracks: newFilteredTracks });
    }
    if (Array.isArray(data))
      data.forEach(x => updateRecording(x));
    else
      updateRecording(data);
  }

  getData = () => {
    console.log("fetching data", !!this.props.user);
    const { store, match, history } = this.props;
    let recordingId = null;
    let trackId = match.params.trackId || 0;
    if (trackId === "account")
      trackId = 0;
    if (trackId) {
      recordingId = match.params.recordingId || 0;
    }
    store.setIsFetching(true);
    const getFunc = this.props.user ? api.getTracksLogged.bind(api) : api.getTracks.bind(api);
    let currentUser = this.props.user;
    getFunc(this.props.portal.portalName).then(resp => {
      console.log("fetched data", !!currentUser);
      if (currentUser !== this.props.user)
        return;
      const tracks = [...resp.body].map(x => ({ ...x, recordings: x.recordings?.map(c => ({ ...c, priceForMembers: c.priceForMembers || config.portalPriceForMembers, price: c.price || config.portalPrice })) }));
      const recordings = tracks.map(x => x.recordings).filter(Boolean).flat();
      const recording = recordings.find(x => x.id === recordingId);
      if (tracks.length === 1)
        trackId = tracks[0].id;
      if (this.isUnmounted) return;
      this.setState({
        tracks,
        filteredTracks: tracks,
        tracksSearchMatch: new Array(tracks.length).fill([]),
      }, () => {
        this.handleFilterTracks(trackId)();
        store.setIsFetching(false);
        if (recording) {
          const { ratio, alias, questionsCount, evaluationFormMetaData } = recording;
          const link = (questionsCount > 0 && alias) ? this.props.portal.urlPrefix + `/quizzes/${recording.id}` : null;
          let price = currentUser?.isMember ? recording.priceForMembers : recording.price;
          if (link)
            history.push(link);
          else if (alias)
            this.handleShowModal("isRecordOpened", { alias, ratio, id: recording.id, evaluationFormMetaData, name: recording.name });
          else {
            if (price)
              this.handleShowModal("isRecordBuyOpened", recording);
            else
              history.push(this.props.location.pathname + "/sign-up");
          }
        }
      });
    });
  }

  handleShowModal = (name, data = {}, secondary) => {
    this.setState({ [name]: !this.state[name], data, [secondary]: true });
  }

  scrollToStart = () => {
    Scroll.scroller.scrollTo('tracks-start', {
      smooth: true,
      offset: uiConfig.trackPageScrollOffset,
    })
  }

  handleFilterTracks = filter => () => {
    if (filter === this.state.filter || filter === "login") return;
    const { history, portal, location: { pathname } } = this.props;
    console.log("filter", filter, this.state.filter);
    if (filter) {
      if (!pathname.includes(filter))
        history.replace(portal.urlPrefix + "/on-demand/" + filter);
    } else
      history.replace(portal.urlPrefix + "/on-demand");
    this.setState({ filter }, this.scrollToStart);
  }

  handleFilterTracksType = (typeFilter) => () => {
    this.setState({ typeFilter }, this.scrollToStart)
  }

  handleSearch = () => {
    const { tracks } = this.state,
      { search } = this.props,
      tracksSearchMatch = new Array(tracks.length).fill([]);
    let cmeTotal = 0, nonCmeTotal = 0;

    if (search.length < 2 && (this.state.cmeTotal !== cmeTotal || this.state.nonCmeTotal !== nonCmeTotal || tracksSearchMatch.join() !== tracksSearchMatch.join()))
      return this.setState({ tracksSearchMatch, cmeTotal, nonCmeTotal });
    this.state.filteredTracks.forEach((track, trackIndex) => {
      (track.recordings || []).forEach((rec, recIndex) => {
        const speakerList = rec.speakers.map((speaker) => `${speaker.firstName} ${speaker.lastName}`).join(" ").toLowerCase().indexOf(search) !== -1,
          description = (rec.summary || "").toLowerCase().indexOf(search.toLowerCase()) !== -1,
          title = (rec.name || "").toLowerCase().indexOf(search.toLowerCase()) !== -1;
        if (speakerList || title || description) {
          tracksSearchMatch[trackIndex] = [...tracksSearchMatch[trackIndex], recIndex];
          if (rec.ceHours)
            cmeTotal++
          else
            nonCmeTotal++;
        }
      });
    });
    this.setState({ tracksSearchMatch, isSearching: false, cmeTotal, nonCmeTotal })
  }

  notFoundMessage = (data) => {
    const { isSearching, filter, typeFilter, cmeTotal, nonCmeTotal } = this.state,
      { search } = this.props,
      searchDone = search.length > 1 && !isSearching;
    let result = false,
      text = "";
    if (data) {
      if (data.id === filter) {
        if (typeFilter === 1) {
          result = uiConfig.CMEfilter && !data.withCME;
          text = "CME ";
        } else if (typeFilter === 2) {
          result = uiConfig.CMEfilter && !data.nonCME;
          text = "non-CME ";
        } else {
          result = !(data.nonCME + data.withCME);
        }
      }
    } else {
      if (typeFilter === 1) {
        result = !filter && !cmeTotal;
        text = "CME ";
      } else if (typeFilter === 2) {
        result = !filter && !nonCmeTotal;
        text = "non-CME ";
      } else {
        result = !filter && !cmeTotal && !nonCmeTotal;
      }
    }
    if (searchDone && result) {
      return <><br /><br /><Typography>Sorry, we could not find any {text}recordings matching &quot;<b>{search}</b>&quot;.</Typography></>
    }
  }

  render() {
    const { filteredTracks, tracksSearchMatch, filter, typeFilter, tracks, recordingDetails } = this.state,
      { search, isSm, portal, user } = this.props;
    const hideTracks = filter || (search.length > 1);
    const showFilter = tracks.length > 1;
    return (
      <>
        <LogOnMount eventType="tracks visit" eventProperties={{ page: "tracks" }} />
        <br />
        <Grid container spacing={2}>
          {showFilter &&
            <Hidden mdUp>
              <Grid item sm={6} xs={12}>
                <Filter title="Topics" handleItemClick={this.handleFilterTracks} items={tracks} selected={filter} defaultItem={isSm ? null : { id: 0, title: "All Tracks" }} />
              </Grid>
              <Grid item sm={6} xs={12}>
                {uiConfig.CMEfilter &&
                  <Filter
                    title="Types"
                    handleItemClick={this.handleFilterTracksType}
                    items={[{ id: 1, title: "CME Only" }, { id: 2, title: "Non-CME" }]}
                    selected={typeFilter}
                    defaultItem={{ id: 0, title: "All Types" }}
                  />
                }
              </Grid>
            </Hidden>
          }
          <Grid item md={showFilter ? 10 : 12} sm={12}>
            <Scroll.Element name="tracks-start" />
            {hideTracks ?
              filteredTracks.map((item, index) => {
                const check = item.id === filter || (search.length > 1 && tracksSearchMatch[index].length > 0 && !filter);
                let nonCME = 0, withCME = 0;
                if (!check) return null;
                return (
                  <Fragment key={index}>
                    <br />
                    {showFilter && <Typography variant="h6" gutterBottom>{item.title}</Typography>}
                    <Grid container spacing={3} justify="flex-start">
                      {!!check && item.recordings && item.recordings.map((record, recordIndex) => {
                        const searchCheck = search.length < 2 || tracksSearchMatch[index].includes(recordIndex);
                        const typeCheck = !typeFilter || (typeFilter === 1 && record.ceHours) || (typeFilter === 2 && !record.ceHours);
                        if (searchCheck) {
                          if (record.ceHours)
                            withCME++;
                          else
                            nonCME++;
                        }
                        if (!searchCheck || !typeCheck) return null;
                        return (
                          <Grid item xs={12} sm={6} md={4} key={record.id}>
                            <RecordingCard recording={record} openModal={this.handleShowModal} openDetails={this.handleDetailsModalOpen} user={user} />
                          </Grid>
                        )
                      })
                      }
                    </Grid>
                    {this.notFoundMessage({ id: item.id, nonCME, withCME })}
                  </Fragment>
                )
              }
              ) :
              <Hidden smDown>
                {portal.uiSettings.trackImages ?
                  <TracksGridList tracks={filteredTracks} handleClick={this.handleFilterTracks} cols={4} /> :
                  <TracksList tracks={filteredTracks} handleClick={this.handleFilterTracks} />
                }
              </Hidden>
            }
            {this.notFoundMessage()}
          </Grid>
          {showFilter &&
            <Hidden smDown>
              <Grid item md={2} sm={12}>
                <div style={{ position: 'fixed', top: uiConfig.main.childrenMarginTop + 20 }}>
                  <Filter title="Topics" handleItemClick={this.handleFilterTracks} items={tracks} selected={filter} defaultItem={{ id: 0, title: "All Topics" }} />
                  {uiConfig.CMEfilter &&
                    <Filter
                      title="Types"
                      handleItemClick={this.handleFilterTracksType}
                      items={[{ id: 1, title: "CME Only" }, { id: 2, title: "Non-CME" }]}
                      selected={typeFilter}
                      defaultItem={{ id: 0, title: "All Types" }}
                    />
                  }
                </div>
              </Grid>
            </Hidden>
          }
        </Grid>
        {this.state.isRecordOpened && <RecordingDialog video={this.state.data} user={this.props.user} hide={this.handleShowModal.bind(this, "isRecordOpened")} />}
        {this.state.isRecordBuyOpened && <SignUpAndBuyDialog recording={this.state.tracks.map(x => x.recordings).flat().find(x => x?.id === this.state.data.id)} handleClose={this.handleBuyClose} />}
        {/* {!this.state.isRecordBuyOpened && <BuyRecordDialog recording={this.state.data} handleClose={this.handleBuyClose} user={this.props.user} />} */}
        <RecordingDetailsDialog show={!!recordingDetails.id} recording={recordingDetails} handleClose={this.handleDetailsModalClose} />
        <br />
      </>
    );
  }
}

export default withRouter(inject(({ store }, props) => ({
  search: store.search, store, user: store.currentUser
}))(props => {
  const portal = usePortal();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return <TrackPage {...props} portal={portal} isSm={isSm} />
}));
