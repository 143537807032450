import React, { useState, useMemo, useEffect, useCallback, useReducer } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { /* makeStyles, */ useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomFormFields from '../CustomFormFields';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import api from "../../api";
import CloseButton from "../CloseButton";
import jsonLogic from 'json-logic-js';
import { useFeedback } from '../feedback/Service';

const setValidity = (id, text) => {
  const el = document.getElementById(id);
  el.setCustomValidity(text);
  el.reportValidity();
}

const reducer = (state, newState) => ({
  ...state,
  ...newState
});

const EvaluationDialog = ({ event, recording, handleClose, entity, open = true }) => {
  const [sessionId, setSessionId] = useState('');
  const [sessions, setSessions] = useState([]);
  const session = useMemo(() => sessions.find(x => x.id === sessionId), [sessions, sessionId]);
  const evaluationFormMetaData = useMemo(() => entity === "session" ? session?.evaluationFormMetaData : recording?.evaluationFormMetaData, [entity, session, recording]);
  // console.log("session", session, evaluationFormMetaData)
  const metaData = useMemo(() => evaluationFormMetaData ? JSON.parse(evaluationFormMetaData) : null, [evaluationFormMetaData]);
  const initialState = useMemo(() => metaData ? Object.fromEntries(metaData.fields.map(x => [x.id, x.type === "checkbox" ? x.defaultChecked : x.defaultValue])) : {}, [metaData]);
  const initialErrorState = useMemo(() => metaData ? Object.fromEntries(metaData.fields.map(x => [x.id, null])) : {}, [metaData]);
  const [state, setState] = useReducer(reducer, initialState);
  const [errors, setErrors] = useReducer(reducer, initialErrorState);
  // const [formData, setFormData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const feedback = useFeedback();
  const size = metaData?.size || "sm";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async e => {
    e.preventDefault();
    if (!sessionId && entity === "session") {
      return feedback.alert({ title: 'Please choose session' });
    }
    for (let field of metaData.fields) {
      if (field.condition && !jsonLogic.apply(field.condition, state))
        continue;
      if (field.type === "phone" && field.mandatory) {
        if (state[field.id].length < 8) {
          console.warn("validation error", field.id);
          setValidity(field.id, field.label + " required");
          return;
        }
      }
      if (field.type === "radio" && field.mandatory) {
        if (state[field.id] === field.defaultValue) {
          console.warn("validation error", field.id);
          setValidity(field.id + "0", "Please choose an option");
          return;
        }
      }
      if (field.type === "checkbox" && field.mandatory) {
        if (!state[field.id]) {
          console.warn("validation error", field.id);
          setValidity(field.id, "You must check to proceed");
          return;
        }
      }
      if (field.type === "select" && field.mandatory) {
        if (!state[field.id]) {
          console.warn("validation error", field.id);
          setErrors({ [field.id]: "Please choose an option" })
          // setValidity(field.id, "Please choose an option");
          return;
        }
      }
    }
    try {
      setIsFetching(true);
      const data = { sessionId: entity === "session" ? sessionId : recording.id, evaluationFormData: JSON.stringify(state) };
      await api.evaluateSession(data);
      feedback.snackbar({ text: "Submitted", type: "success" });
      const newSessions = sessions.filter(x => x.id !== sessionId);
      // if (newSessions.length > 0)
      //   setSessionId(newSessions[0].id)
      setSessionId('');
      setSessions(newSessions);
      setState({ ...initialState });
      if (entity !== "session")
        handleClose();
    }
    catch (error) {
      console.warn("Session Evaluation error", error.response);
      feedback.snackbar({ text: error.response?.body?.message || "Unknown error", type: "error" });
    }
    setIsFetching(false);
  }

  const getSessions = useCallback(() => {
    if (entity !== "session")
      return;
    const getData = async () => {
      setIsFetching(true);
      const sessions = await api.getSessionForEvaluation(event.id);
      console.log(sessions);
      setSessions(sessions);
      // if (sessions.length > 0)
      //   setSessionId(sessions[0].id);
      setIsFetching(false);
    }
    getData();
  }, [event]);

  useEffect(() => {
    if (sessions.length > 0) return;
    getSessions();
  }, [getSessions]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="eval-dialog-title" fullWidth maxWidth={size} fullScreen={fullScreen} scroll="body">
      <CloseButton onClick={handleClose} />
      {isFetching && <LinearProgress variant="query" />}
      <DialogTitle id="eval-dialog-title">Session Evaluation</DialogTitle>
      <DialogContent style={fullScreen ? {} : { overflow: 'hidden' }}>
        {(sessions.length > 0 || entity !== "session") ?
          <>
            <DialogContentText>
              Please complete a survey for the session you watched to receive your certificate. Your certificate will be emailed to you and will also be downloadable in your Account area of this platform.
            </DialogContentText>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {entity === "session" &&
                  <Grid item xs={12}>
                    <FormControl required fullWidth disabled={isFetching}>
                      <InputLabel id={"sessions-label"}>Session</InputLabel>
                      <Select
                        labelId={"sessions-label"}
                        value={sessionId}
                        onChange={({ target: { value } }) => setSessionId(value)}
                        fullWidth
                      >
                        <MenuItem value=''></MenuItem>
                        {sessions.map(x => <MenuItem key={x.id} value={x.id}>{x.title}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                }
                <CustomFormFields metaData={metaData} state={state} errors={errors} setState={setState} setErrors={setErrors} />
                <Grid item xs={12}>
                  <Button type="submit" fullWidth variant="contained" color="secondary" disabled={isFetching}>Submit</Button>
                  <br />
                  <br />
                </Grid>
              </Grid>
            </form>
          </> :
          <DialogContentText>No sessions to evaluate so far.</DialogContentText>
        }
      </DialogContent>
    </Dialog>
  );
}

export default EvaluationDialog;
