import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import LockIcon from '@material-ui/icons/Lock';
// import Video from '../quiz/Video';
import ReactPlayer from 'react-player/lazy';
import { useFeedback } from '../feedback/Service';
import ChatDialog from '../dialogs/ChatDialog';
// import usePortal from '../hooks/portal';
import api from '../../api';
import draftToHtml from 'draftjs-to-html';
import ContactConfirmationDialog from '../dialogs/ContactConfirmationDialog';
import PdfViewer from '../PdfViewer';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';
import config from '../../uiConfig';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: 20,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    marginTop: 5,
    marginBottom: 15,
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
  }
}));

const File = ({ file, unlocked, exhibitorId, handleSelect, isSelected }) => {
  const [loaded, setLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileClick = () => handleSelect(file.id);

  const handleFileDownload = async () => {
    if (isLoading) return;
    // if (file.locked && !unlocked) {
    //   e.preventDefault();
    //   const allowed = await feedback.confirm({ title: "Get file access", text: "Share your contact information to the exhibitor to get access to this file?" });
    //   if (allowed) {
    //     setUnlocked(true);
    //     const { email, phone } = exhibitor;
    //     try {
    //       await api.requestContent(portalName, { email, phone });
    //     } catch (error) {
    //       console.error("error sending content request", error);
    //     }
    //   }
    // }
    setIsLoading(true);
    const onProgress = ({ percent }) => setLoaded(percent);
    api.getExhibitorFile(exhibitorId, file.id, onProgress).then((blob) => {
      const url = URL.createObjectURL(blob.body);
      const linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = file.name + ".pdf";
      linkEl.click();
      setIsLoading(false);
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  const isLocked = file.locked && !unlocked;

  return (
    <>
      <ListItem button key={file.id} onClick={handleFileClick} selected={isSelected}>
        <ListItemText primary={file.name} />
        {isLocked ?
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon> :
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="download" onClick={handleFileDownload}>
              <DownloadIcon />
            </IconButton>
          </ListItemSecondaryAction>
        }
      </ListItem>
      {isLoading && <LinearProgress variant="determinate" value={loaded} style={{ width: '100%' }} />}
    </>
  )
}

const Exhibitor = ({ event, store, user }) => {
  const [exhibitor, setExhibitor] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  // const [pdfUrl, setPdfUrl] = useState(null);
  const [unlocked, setUnlocked] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [callNowSent, setCallNowSent] = useState(false);
  const [callLaterSent, setCallLaterSent] = useState(false);
  const [contactConfirmationRepresentativeId, setContactConfirmationRepresentativeId] = useState(null);

  const classes = useStyles();
  const feedback = useFeedback();
  const { exhibitorId } = useParams();
  const history = useHistory();
  // const { portalName } = usePortal();

  const handleContactConfirmationClose = async data => {
    const representativeId = contactConfirmationRepresentativeId;
    setContactConfirmationRepresentativeId(null);
    if (data) {
      setIsSending(true);
      try {
        // console.log(data, representativeId);
        await api.callMeNow(exhibitorId, representativeId, data);
        // await api.callMeNow('0cf4c6cf-0fe0-4b33-a8e3-30f705700548', '98c5a28e-091f-4ded-b494-3092831af5c1', data);
        setCallNowSent(true);
        feedback.snackbar({ text: "Notification sent to the exhibitor", type: "success" });
      } catch (error) {
        console.error("error sending call request", error);
        feedback.snackbar({ text: "Error sending call request", type: "error" });
      }
      setIsSending(false);
    }
  }


  const handleOpenChat = id => () => {
    console.log("chat open")
    api.trackChat(exhibitorId, id).catch(error => console.log(error));
    setShowChat(true);
  }

  const handleCallNowClick = representative => async e => {
    if (representative.callMeNowType === "url")
      return;
    e.preventDefault();
    setContactConfirmationRepresentativeId(representative.id);
  }

  const handleCallLaterClick = representative => async () => {
    setIsSending(true);
    try {
      await api.callMeLater(exhibitorId);
      feedback.snackbar({ text: "Notification sent to the exhibitor", type: "success" });
      setCallLaterSent(true);
    } catch (error) {
      console.error("error sending call request", error);
      feedback.snackbar({ text: "Error sending call request", type: "error" });
    }
    setIsSending(false);
  }

  useEffect(() => {
    let didCancel = false;
    if (!exhibitorId) return
    const getData = async () => {
      store.setIsFetching(true);
      let data;
      try {
        data = await api.getExhibitor(exhibitorId);
      }
      catch (error) {
        console.log(error.response, window.location);
        if (error?.response?.statusCode === 401)
          history.push(window.location.pathname + "/login");
        store.setIsFetching(false);
        return;
      }
      if (didCancel) return;
      // for (let representative of data.representatives) {
      //   try {
      //     // const { uid } = await api.getChatUserId(representative.email);
      //     // console.log("chat uid", uid, representative);
      //     representative.chatUid = representative.chatUserId;
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
      // console.log(data);
      setExhibitor(data);
      store.setIsFetching(false);
    }
    getData();
    return () => { didCancel = true }
  }, [exhibitorId]);

  useEffect(() => {
    if (selectedMedia || !exhibitor)
      return;
    if (exhibitor.videoUrl) {
      setSelectedMedia("video");
      return;
    }
    if (exhibitor.files?.length > 0) {
      const fileId = exhibitor.files[0].id;
      setSelectedMedia(fileId);
    }
  }, [exhibitor, selectedMedia]);

  if (!exhibitor) return null;
  let mainWidth = 12;
  let contentWidth = 0;
  const hasContent = exhibitor.videoUrl || exhibitor.files.length > 0;
  if (hasContent) {
    // mainWidth = exhibitor.videoUrl ? 6 : 9;
    // contentWidth = exhibitor.videoUrl ? 6 : 3;
    mainWidth = 6;
    contentWidth = 6;
  }
  // console.log("selectedMedia", ((exhibitor.files?.length || 0) + !!exhibitor.videoUrl));
  return (
    <div>
      <ContactConfirmationDialog open={!!contactConfirmationRepresentativeId} handleClose={handleContactConfirmationClose} email={user?.email} phone={user?.phone} />
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item sm={12} md={mainWidth}>
          <Typography variant="h4" gutterBottom>{exhibitor.title}</Typography>
          {exhibitor.subtitle && <Typography variant="h6" gutterBottom>{exhibitor.subtitle}</Typography>}
          {exhibitor.url && <Link href={exhibitor.url} target="_blank" rel="noreferrer noopener">{exhibitor.url}</Link>}
          {exhibitor.description && <Typography component="div" dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(exhibitor.description)) }} />}
          <br />
          {exhibitor.representatives.map(representative => (
            <React.Fragment key={representative.id}>
              {(representative.callMeNowLabel && (representative.callMeNowType === 'url' ? !!representative.callMeNowValue : true)) &&
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleCallNowClick(representative)}
                  disabled={callNowSent || isSending}
                  component="a"
                  href={representative.callMeNowValue}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {representative.callMeNowLabel}
                </Button>
              }
              {representative.callMeLaterLabel &&
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleCallLaterClick(false)}
                  disabled={callLaterSent || isSending}
                >
                  {representative.callMeLaterLabel}
                </Button>
              }
              {(representative.userId && representative.chat && representative.chatUserId && config.showChat) &&
                <Button size="large" color="primary" variant="contained" className={classes.button} onClick={handleOpenChat(representative.id)}>{representative.chat}</Button>
              }
              <ChatDialog show={showChat} handleClose={() => setShowChat(false)} credentials={{ email: user.email, recipientId: representative.chatUserId }} />
              <br />
              <br />
            </React.Fragment>
          ))}
        </Grid>
        {hasContent &&
          <Grid item sm={12} md={contentWidth}>
            {selectedMedia === "video" &&
              <div className={classes.playerWrapper}>
                {exhibitor.videoUrl.startsWith("https://slidespiel.com/view/") ?
                  <iframe
                    className={classes.player}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    src={exhibitor.videoUrl}
                    title={`Recording`} allowFullScreen
                  /> :
                  <ReactPlayer
                    className={classes.player}
                    url={exhibitor.videoUrl}
                    controls width='100%' height='100%'
                    config={{
                      youtube: {
                        playerVars: {
                          rel: 0,
                          loop: 1,
                          playlist: exhibitor.videoUrl.split("/").pop().split("?").shift(),
                          modestbranding: 1,
                        }
                      },
                    }}
                  />
                }
              </div>
            }
            {selectedMedia && selectedMedia !== "video" &&
              <PdfViewer fileId={selectedMedia} exhibitorId={exhibitorId} api={api} />
            }
            {((exhibitor.files?.length || 0) + !!exhibitor.videoUrl) > 1 &&
              <List
                component="nav"
                subheader={
                  <ListSubheader component="div">
                    Content
                  </ListSubheader>
                }
              >
                {exhibitor.videoUrl &&
                  <ListItem button onClick={() => setSelectedMedia("video")} selected={selectedMedia === "video"}>
                    <ListItemText primary={"Video"} />
                  </ListItem>
                }
                {exhibitor.files.map(file => <File file={file} handleSelect={setSelectedMedia} unlocked={unlocked} key={file.id} exhibitorId={exhibitorId} isSelected={file.id === selectedMedia} />)}
              </List>
            }
          </Grid>
        }
      </Grid>
      <br />
    </div>
  )
}

export default Exhibitor;
