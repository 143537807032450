import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiPhoneNumber from 'material-ui-phone-number';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dialog: {
    // padding: '0 0 10px 10px !important',
    // minHeight: 200,
  },
  content: {

  },
}));

const setValidity = (id, text) => {
  const el = document.getElementById(id);
  el.setCustomValidity(text);
  el.reportValidity();
}

const ContactConfirmationDialog = ({ open, handleClose, email: initialEmail, phone: initialPhone, withQuestion, withPhone = true }) => {
  const classes = useStyles();
  const [email, setEmail] = useState(initialEmail || "");
  const [phone, setPhone] = useState(initialPhone || "");
  const [question, setQuestion] = useState("");

  useEffect(() => {
    setEmail(initialEmail || "");
  }, [initialEmail]);
  useEffect(() => {
    setPhone(initialPhone || "");
  }, [initialPhone]);

  const handleOk = e => {
    e.preventDefault();
    if (phone.length < 8 && withPhone) {
      console.warn("phone validation error");
      setValidity("phone", "Phone number required");
      return;
    }
    const result = { email };
    if (withPhone)
      result.phone = phone;
    if (withQuestion)
      result.question = question;
    handleClose(result);
  }
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="chat-dialog-title"
      aria-describedby="chat-dialog-description"
      fullWidth
    >
      <DialogTitle id="chat-dialog-title">{withQuestion ? "Ask a question" : "Confirm Contact Information"}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <form onSubmit={handleOk} id="form-dialog-form">
          {withQuestion &&
            <>
              <TextField
                // autoFocus
                // margin="dense"
                label="Question"
                fullWidth
                multiline
                rows={3}
                name="question"
                value={question}
                required
                onChange={e => setQuestion(e.target.value)}
                autoFocus
              />
              <br />
              <br />
            </>
          }
          <TextField
            // autoFocus
            // margin="dense"
            label={withQuestion ? "Your Email" : "Email"}
            fullWidth
            type={email}
            name="email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
          />
          {withPhone &&
            <>
              <br />
              <br />
              <MuiPhoneNumber
                defaultCountry={'us'}
                onChange={x => setPhone(x)}
                fullWidth
                label="Phone"
                required
                value={phone}
                name="phone"
                id="phone"
              />
            </>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="form-dialog-form" color="primary" autoFocus={!withQuestion}>
          Submit
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContactConfirmationDialog;
